<template>
  <div class="receipt-content-container" @click="selectForFinalReceipt($event)">
    <div class="receipt-header">
      <div class="receipt-header-info">
        <h6 class="receipt-published" v-if="receipt.status=='CLOSED'">Εχει εκδοθεί τελική απόδειξη</h6>
        <h6 class="receipt-number">Προσωρινή απόδειξη {{receipt.series}}-{{receipt.number}}</h6>
        <h6 class="receipt-date">{{getDateForReceipt(receipt.dateOfReceipt)}}</h6>
      </div>

      <div class="receipt-buttons-container">
        <input
          v-if="receipt.status=='OPEN'"
          type="checkbox"
          class="checkbox-for-final"
          v-model="selected"
        />
        <button
          @click="printReceipt()"
          id="print-receipt-btn"
          class="action-button print-receipt-again-button"
        >
          <clip-loader
            v-if="printingReceipt"
            :loading="printingReceipt"
            size="15px"
            color="#ffffff"
          />
          <font-awesome-icon id="print-receipt-icon" v-else icon="print" />
        </button>
      </div>
    </div>
    <div class="receipt-body">
      <div v-for="item in receipt.items" :key="item.id" class="item-in-receipt">
        <span class="item-receipt-quantity">{{item.quantity}}</span>
        <span class="item-receipt-name">{{item.itemName}}</span>
        <span class="item-receipt-price">{{item.totalPrice.toFixed(2)}}</span>
      </div>
    </div>
    <div class="receipt-footer">
      <span class="receipt-total-amount">Σύνολο: {{calculateTotalOfReceipt(receipt.items)}}€</span>
    </div>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
export default {
  name: "TempReceipt",
  components: { ClipLoader },
  data() {
    return {
      selected: false,
      printingReceipt: false
    };
  },
  props: ["receipt"],
  methods: {
    getDateForReceipt(dateIso) {
      let date = new Date(dateIso);

      let day = date.getDate();
      day = day < 10 ? `0${day}` : day;
      let month = date.getMonth() + 1;
      month = month < 10 ? `0${month}` : month;

      let hours = date.getHours();
      hours = hours < 10 ? `0${hours}` : hours;
      let minutes = date.getMinutes();
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      let dateString = `${day}-${month}-${date.getFullYear()} ${hours}:${minutes}`

      return dateString;
    },

    calculateTotalOfReceipt(items) {
      let total = 0;
      items.forEach(item => total = total + item.totalPrice);
      return total.toFixed(2);
    },

    selectForFinalReceipt(event) {
      if (!(event.target.id == 'print-receipt-icon' || event.target.id == 'print-receipt-btn' || event.target.id == 'cancel-receipt-btn' || event.target.id == 'cancel-receipt-icon' || event.target.tagName == 'path') && !this.printingReceipt && this.receipt.status == 'OPEN') {
        this.selected = !this.selected;
        this.$emit("select-temp-receipt", this.receipt);
      }

    },

    async printReceipt() {
      this.printingReceipt = true;
      this.$emit("print-temp-receipt", this.receipt.id);
      setTimeout(() => {
        this.printingReceipt = false;
      }, 1600);
    }
  },
  mounted() {

  }
};
</script>

<style scoped>
.receipt-content-container {
  display: flex;
  flex-direction: column;
  margin: 4px 8px 4px 8px;
  padding-bottom: 4px;
  border: 2px solid rgb(0, 106, 219, 0.7);
  border-radius: 8px;
  margin-bottom: 16px;
}

.receipt-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 6px;
  padding-top: 4px;
  border-bottom: 1px solid rgb(94, 94, 94);
  background-color: rgb(0, 106, 219, 0.24);
  border-radius: 8px 8px 0px 0px;
}

.receipt-header-info {
  display: flex;
  flex-direction: column;
}

.receipt-published {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0px;
  color: #0275d8;
}

.receipt-number {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
  color: rgb(29, 29, 29);
}

.receipt-date {
  font-size: 13px;
  font-weight: 500;
  color: rgb(58, 58, 58);
  margin-bottom: 4px;
}

.receipt-buttons-container {
  display: flex;
  flex-direction: row;
}

.print-receipt-again-button {
  padding: 4px 8px 4px 8px;
  border-radius: 6px;
  cursor: pointer;

  color: white;
  background-color: #198b4a;
  font-size: 15px;
  margin-right: 4px;
}

.receipt-body {
  display: flex;
  flex-direction: column;
}

.item-in-receipt {
  padding-left: 6px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  padding-bottom: 5px;
  padding-top: 5px;
  border-top: 1px solid rgb(186, 186, 186);
}

.item-receipt-quantity {
  font-size: 17px;
  font-weight: 700;
  margin-right: 5px;
  color: #198b4a;
}

.item-receipt-name {
  font-size: 16px;
  font-weight: 600;
  margin-right: 5px;
  color: rgb(47, 47, 47);
}

.item-receipt-price {
  font-size: 15px;
  font-weight: 500;
  color: rgb(47, 47, 47);
}

.receipt-footer {
  display: flex;
  flex-direction: row;
  border-top: 1px solid rgb(126, 126, 126);
  padding-top: 7px;
  padding-bottom: 4px;
}

.receipt-total-amount {
  font-size: 18px;
  font-weight: 700;
  padding-left: 8px;
  color: rgb(79, 79, 79);
}

.checkbox-for-final {
  width: 28px;
  margin-right: 10px;
  border-radius: 30px;
}
</style>